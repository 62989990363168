import React from 'react';
import * as serviceWorker from './serviceWorker';
import 'react-toastify/dist/ReactToastify.min.css';
import App from './App';
// import {inicializeFirebase} from './Utils/push-notifications/push-notification';

// Source: https://youtu.be/j-WcyAjVceM
async function renderAsync() {
    const [
        React,
        {hydrate},
        {default: App},
    ] = await Promise.all([
        import("react"),
        import("react-dom"),
        import("./App"),
        import("./RouteAuth0")
    ]);

    // Inject react app components into App's Shell
    hydrate(<App/>, document.getElementById('root'));
}

const renderStart = Date.now();
const startupTime = renderStart - window.performance.timing.responseStart;

renderAsync().then(() => {
    const renderTime = Date.now() - renderStart;
});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
const serviceWorkerOnUpdate = registration => {
    // const r = window.confirm("New app version available! Reload page?");
    console.log("serviceWorkerOnUpdate")
};

const serviceWorkerOnSuccess = registration => {
    if (window.confirm("This application has been updated in the background, select 'Ok' to reload the page, and get the latest features. Otherwise it will be automatically reloaded in one minute.")) {
        // console.log('window.confirm && true');
        navigator.serviceWorker.controller.postMessage('RELOAD_CLIENTS');
    } else {
        // console.log('window.confirm && false');
        setTimeout(
            () => navigator.serviceWorker.controller.postMessage('RELOAD_CLIENTS')
            , 60000
        );
    }
};

serviceWorker.register({onSuccess: serviceWorkerOnSuccess, onUpdate: serviceWorkerOnUpdate});
//
// inicializeFirebase();
//
// if ('serviceWorker' in navigator) {
//     navigator.serviceWorker.register('../firebase-messaging-sw.js')
//         .then(function(registration) {
//             console.log('Registration successful, scope is:', registration.scope);
//         }).catch(function(err) {
//         console.log('Service worker registration failed, error:', err);
//     });
// }
