import socketIOClient from "socket.io-client";
import {Observable, Subject} from 'rxjs/Rx';

class SubscriptionNotificacionService {
    defaultOptions = {
        accessToken: '',
        subscriptionPath: "/api/subscription-notification",
        baseURI: process.env.REACT_APP_NOTIFICATION_URL ||  "http://localhost:4001"
    };

    rooms = {};
    observable;
    observer;
    events = {};
    connection = new Subject();

    constructor() {
        this.observable = Observable.create((observer) => {
            this.observer = observer;
        }).share();
    }

    connect($options, callback) {
        const options = Object.assign({}, this.defaultOptions, ($options || {}));

        if (this.socket && this.options) {
            const lastOptions = Object.keys(this.options);
            if (!lastOptions.some(key => options[key] !== this.options[key])) return;
            this.disconnect();
        }
        this.options = options;

        let query = options.accessToken ? {token: options.accessToken} : {}

        const socket = socketIOClient(options.baseURI, {
            path: options.subscriptionPath,
            query: query
        });
        socket.on("connect", (data) => {
            //console.log('check 2', socket.connected);
            this._isConnected = true;
            this.connection.next({event: 'connected'});
            callback && callback({status: "connected", data: data});
            if (this._isHasRoomSubscriptions())
                this._joinTo(this._getAllRooms())
        });
        socket.on("disconnect", () => {
            this._isConnected = false
            this.connection.next({event: 'disconnected'});
            callback && callback({status: "disconnected"});
        });
        socket.on("error", (e) => {
            this._isConnected = false;
            this.connection.next({event: 'disconnected', data: e});
            callback && callback({error: e, status: "disconnected"});
        });
        this.socket = socket;
    }


    subscribeSocketStatus(callback) {
        return this.connection.subscribe(callback);
    }

    _isHasRoomSubscriptions() {
        return Object.keys(this.rooms).length;
    }

    _getAllRooms() {
        return Object.keys(this.rooms);
    }

    disconnect() {
        if (!this.getSocket()) return;
        this.getSocket().disconnect();
        this.events = {};
        this.observable = Observable.create((observer) => {
            this.observer = observer;
        }).share();
    }

    isConnected() {
        return this._isConnected;
    }

    getSocket() {
        return this.socket;
    }

    /**
     * Method to broadcast the event to observer
     */
    _notifyAll(event) {
        if (this.observer)
            this.observer.next(event);
    }

    /**
     * Subscribe  to an event to specific room. The room is used to minimise the event listeners
     * @param event {String} event subscription
     * @param filter {Object} data filter
     * @param callback {function} function that we will call for the event
     * @return {Subscription}
     * */
    subscribe(event, filter, callback) {
        this._subscribe(event)
        return this.observable.filter((data) => {
            // console.info("TRIGGER_EVENT_SUSCRIPTION", data.event);
            // console.log(data.data);
            // console.log(filter)
            return data.event === event && this._filter(data.data, filter);
        }).subscribe(callback);
    }

    _filter(data, filter = {}) {
        return !Object.keys(filter).some((key) => {
            if (key === '__OR') {
                const value = !this._orFilter(data, filter.__OR);
                return value;
            }
            return data[key] !== filter[key]
        });
    }

    _orFilter(data, filter = {}) {
        return !Object.keys(filter).some((key) => {
            return data[key] === filter[key]
        });
    }


    /**
     * Method to subscribe to an event with callback
     * @return {Void}
     */
    _subscribe(event) {
        if (!this.events[event]) {
            this.socket.on(event, (data) => {
                this._notifyAll({event, data})
            });
            this.events[event] = true;
        }
    }

    /**
     * Joint to specific room. The room is used to minimise the event listeners
     * @param room {Object} room information
     * @example {
     *   entity: 'PROJECT'
     *   id: '123456'
     * }
     * */
    joinTo(room = {}) {
        const roomId = this._buildRoomId(room);
        if (!roomId) return; // just in case :)
        if (!this.rooms[roomId]) {
            this.rooms[roomId] = 0;
            this._joinTo(roomId)
        }
        this.rooms[roomId]++;
    }

    /**
     * leave to specific room. The room is used to minimise the event listeners
     * @param room {Object} room information
     * @example {
     *   entity: 'PROJECT'
     *   id: '123456'
     * }
     * */
    leave(room) {
        const roomId = this._buildRoomId(room);
        if (!this.rooms[roomId]) {
            return;
        }
        this.rooms[roomId]--;
        if (this.rooms[roomId] <= 0) {
            this._leave(roomId)
            delete this.rooms[roomId];
        }
    }


    _joinTo(rooms) {
        if (this.isConnected())
            this.socket.emit("subscribe", rooms);
    }

    _leave(rooms) {
        if (this.isConnected())
            this.socket.emit("unsubscribe", rooms);
    }

    /**
     * @param room {Object} room information
     * @example {
     *   entity: 'PROJECT'
     *   id: '123456'
     * }
     * */
    _buildRoomId(room) {
        let roomId = room.entity;
        if (room.id)
            roomId += `_${room.id}`;
        //todo: more logic
        return roomId;

    }

    unsubscribe(subscriber) {
        if (subscriber)
            subscriber.unsubscribe();
    }

}

export default new SubscriptionNotificacionService();
