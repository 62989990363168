import React from 'react';
import styled from "styled-components";

const RootLoader = styled.div`
    height: 80vh;
`

const LoaderContainer = styled.div`
    height: 100%;
    transition: opacity 200ms cubic-bezier(0, -0.26, 0.32, 1.22);
    display: flex;
    align-items: center;
    justify-content: center;
`

const Loader = styled.div`
    border: 2px solid #f3f3f3;
    border-radius: 50%;
    border-top: 2px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: spin 1s linear infinite; /* Safari */
    animation: spin 1s linear infinite;
    position: absolute;
    
    @-webkit-keyframes spin {
         0% {
            -webkit-transform: rotate(0deg);
         }
         100% {
            -webkit-transform: rotate(360deg);
         }
     }

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const LoaderImage = styled.div`
    margin-top: 9px;
    margin-left:7px;
    
    .text span{
        color: #3498db;
        font-size: 0.72rem;
    }
`

export {
    RootLoader,
    LoaderContainer,
    Loader,
    LoaderImage
}
