import {applyMiddleware, createStore} from 'redux';
// Middleware
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers/index';

/**
 * Logs all actions and states after they are dispatched.
 */
const logger = store => next => action => {
    if (process.env.NODE_ENV !== 'production') {
        console.group(action.type);
        console.info(`Dispatching: ${action}`);
        console.groupEnd(action.type);
    }
    return next(action);
};

const createStoreWithMiddleware = applyMiddleware(thunkMiddleware, logger)(
    createStore,
);

const devtool =
    window.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV !== 'production' && window.__REDUX_DEVTOOLS_EXTENSION__();
const store = createStoreWithMiddleware(rootReducer, devtool);

export default store;
