import * as types from "../../actions/dropdownpopover/types";

const initialState = {
    type_popover: null,//"SUBMIT_TICKET",
    payload: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.LAUNCH_DROPDOWNPOPOVER:
            return Object.assign({}, state, {
                type_popover: action.type_popover,
                payload: action.payload ? action.payload : null
            });
        default:
            return state;
    }
};
