import * as types from '../../actions/lateralMenu/types';

const initialState = {
    collapsed: true
};


export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.TOGGLE_COLLAPSE:
            return Object.assign({}, state, {
                collapsed: !state.collapsed
            });
        case types.SET_LATERAL_MENU_COLLAPSE:
            return Object.assign({}, state, {
                collapsed: action.collapse
            });
        default:
            return state;
    }

}