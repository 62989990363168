import * as types from '../../actions/favoriteentities/types';

const initialState = {
    favoriteprojects: [],
    favoritedashboards: [],
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.STORE_FAVORITES_PROJECT:
            return Object.assign({}, state, {
                favoriteprojects: action.payload
            });
        case types.STORE_FAVORITES_DASHBOARD:
            return Object.assign({}, state, {
                favoritedashboards: action.payload
            });
        default:
            return state;
    }

}
