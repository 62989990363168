import * as types from "../../actions/user/types";

const initialState = {
    currentUser: null,
    registerUser: null,
    payload: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.CURRENT_USER:
            return Object.assign({}, state, {
                currentUser: action.currentUser,
                registerUser: action.currentUser ? null : state.registerUser,
                payload: action.payload
            });
        case types.REGISTER_USER:
            return Object.assign({}, state, {
                registerUser: action.registerUser,
                currentUser: action.registerUser ? null : state.currentUser,
                payload: action.payload
            });
        case types.REGISTER_COMPLETED:
            return Object.assign({}, state, {
                register: action.register,
                message: action.message || "We'll send an email to your email, please check it & click the link to activate your account.",
            });
        default:
            return state;
    }
};
