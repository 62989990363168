import React from "react";
import * as compose from 'lodash.flowright';
import {withRouter} from 'react-router-dom'
import {connect} from "react-redux";

import withLocalStorage from "./Components/HOC/withLocalStorage";
import PageLoader from "./Components/PageLoader"
import {AuthQuery} from "./Modules/Auth/ApolloClient";
import {SpaceQuery} from "./ApolloClient/Space";
import {putCurrentSpace} from "./Redux/actions/space";
import {putCurrentUser} from "./Redux/actions/user";
import Subscription from "./Utils/Subscription";
import SubscriptionNotification from "./Utils/SubscriptionNotification";
import * as theme from "./theme"
import graphqlService from "./graphqlService";
import {setLateralMenuCollapse} from "./Redux/actions/lateralMenu";

/**
 * @summary Injects global data (current user, global settings, whatever) into
 * child components.
 */

class GlobalDataProvider extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            loadingTheme: true,
            api: {
                currentUser: null,
                loading: false,
                theme: null,
                logoData: {}
            }
        }
    }

    componentDidMount() {
        this.buildData();
        this.buildSpaceSetting();
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.currentUser) {
            this.buildData()
        }
    }

    buildData = () => {
        const {putCurrentUser} = this.props;
        this.setState({loading: true});
        graphqlService.query({
            query: AuthQuery.getCurrentUser,
            variables: {},
            fetchPolicy: "network-only"
        }).then(res => {
            const data = res.data && res.data.me ? res.data.me : null;
            if (data) {
                putCurrentUser(data.user, {
                    roles: data.roles || [],
                    rolesCompress: this.mappingRoles(data && data.roles || []),
                    permissions: data.permissions
                });
                this.setState({
                    loading: false
                })
            }
        }).catch(err => {
            let error = err.graphQLErrors && err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : "An error occurred while processing the request. Try it later";
            this.setState({
                error: error,
            })
        })
    };

    buildSpaceSetting = () => {
        const {putCurrentSpace, getStorageItem} = this.props;
        this.setState({loading: true});
        graphqlService.query({
            query: SpaceQuery.SpaceFindOne,
            variables: {
                filter: {name: window.location.hostname}
            },
            fetchPolicy: "network-only"
        }).then(res => {
            const THEME = "tek_task_space_theme";
            const data = res.data && res.data.SpaceFindOne ? res.data.SpaceFindOne : null;
            localStorage.setItem(THEME, data && data ? data.theme : null);
            if (data) {
                putCurrentSpace(data, {});
                const baseTheme = JSON.parse(JSON.stringify(theme));
                let themeCustom = JSON.parse(getStorageItem(THEME));
                let mockUserTheme = themeCustom ? themeCustom : {};
                let spaceTheme = {};

                Object.keys(theme).forEach(keyName => {
                    spaceTheme[keyName] = mockUserTheme[keyName] ?
                        Object.assign(JSON.parse(JSON.stringify(baseTheme))[keyName], {}, mockUserTheme[keyName]) : JSON.parse(JSON.stringify(baseTheme[keyName]))
                });

                this.setState({
                    loadingTheme: false,
                    api: Object.assign(this.state.api, {}, {theme: spaceTheme, logoData: spaceTheme.logo})
                })
            }
        }).catch(err => {
            let error = err.graphQLErrors && err.graphQLErrors.length > 0 ? err.graphQLErrors[0].message : "An error occurred while processing the request. Try it later";
            this.setState({
                error: error,
            })
        })
    };

    mappingRoles = (roles) => {
        return JSON.parse(JSON.stringify(roles)).map(item => item.name)
    };

    render() {
        const {
            children, getStorageItem, narrowScreen, setLateralMenuCollapse
        } = this.props;

        setLateralMenuCollapse(narrowScreen);

        const {loading, loadingTheme, api} = this.state;

        let token = getStorageItem("tek_task_access_token");
        Subscription.connect({accessToken: token});
        SubscriptionNotification.connect({accessToken: token});

        return loading || loadingTheme ? <PageLoader/> : children(api)
    }
}

GlobalDataProvider.propTypes = {};

const mapStateToProps = (state) => {
    const {user, space} = state;
    return {
        currentUser: user.currentUser,
        currentSpace: space.currentSpace
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        putCurrentUser: (user, payload) => {
            dispatch(putCurrentUser(user, payload));
        },
        putCurrentSpace: (space, payload) => {
            dispatch(putCurrentSpace(space, payload));
        },
        setLateralMenuCollapse: (collapse) => {
            dispatch(setLateralMenuCollapse(collapse));
        }
    };
};

export default compose(
    connect(mapStateToProps, mapDispatchToProps),
    withRouter,
    withLocalStorage
)(GlobalDataProvider);
