import * as types from "../../actions/sidebar/types";

const initialState = {
    type_sidebar: null,//"SUBMIT_TICKET",
    payload: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.LAUNCH_SIDEBAR:
            return Object.assign({}, state, {
                type_sidebar: action.type_sidebar,
                payload: action.payload ? action.payload : null
            });
        default:
            return state;
    }
};
