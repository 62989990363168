import React, {Component} from "react";

const withLocalStorage = (WrappedComponent) => {

    class withLocalStorageHOC extends Component {

        constructor(props) {
            super(props)
        }

        onHandleGetLocalStorageItem = (item) => {
            return localStorage.getItem(item)
        }

        onHandleGetStorageItem = (item) => {
            return localStorage.getItem(item) || sessionStorage.getItem(item)
        }

        onHandleSetLocalStorageItem = (item, value) => {
            sessionStorage.setItem(item, value)
            return localStorage.setItem(item, value)
        }

        onHandleRemoveLocalStorageItem = (item) => {
            sessionStorage.removeItem(item)
            localStorage.removeItem(item)
        }


        render() {
            return (
                <WrappedComponent
                    getStorageItem={this.onHandleGetStorageItem}
                    getLocalStorageItem={this.onHandleGetLocalStorageItem}
                    setLocalStorageItem={this.onHandleSetLocalStorageItem}
                    removeLocalStorageItem={this.onHandleRemoveLocalStorageItem}
                    {...this.props}
                />
            );
        }
    }

    return withLocalStorageHOC;
}

export default withLocalStorage;
