import React from 'react';
import {createGlobalStyle} from 'styled-components';
import {styledutils} from '../../Utils';
import {base} from '../../theme';
import Color from 'color';

/**
 * Global style for the site base on the theme
 * */


const GlobalStyle = createGlobalStyle`
    
    :root {
        --theme-body-bg-color: ${({theme}) => theme.body['body-bg']};
        --theme-body-color: ${({theme}) => theme.body['body-color']};

        --theme-primary-color: ${({theme}) => theme.themeColor.primary};
        --theme-primary-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.primary, theme.themeColor['theme-color-interval'])};
        --theme-secondary-color: ${({theme}) => theme.themeColor.secondary};
        --theme-secondary-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.secondary, theme.themeColor['theme-color-interval'])};
        --theme-success-color: ${({theme}) => theme.themeColor.success};
        --theme-success-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.success, theme.themeColor['theme-color-interval'])};
        --theme-info-color: ${({theme}) => theme.themeColor.info};
        --theme-info-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.info, theme.themeColor['theme-color-interval'])};
        --theme-warning-color: ${({theme}) => theme.themeColor.warning};
        --theme-warning-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.warning, theme.themeColor['theme-color-interval'])};
        --theme-danger-color: ${({theme}) => theme.themeColor.danger};
        --theme-danger-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.danger, theme.themeColor['theme-color-interval'])};
        --theme-light-color: ${({theme}) => theme.themeColor.light};
        --theme-light-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.light, theme.themeColor['theme-color-interval'])};
        
        --theme-google-color: ${({theme}) => theme.themeColor.google};
        --theme-google-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.google, theme.themeColor['theme-color-interval'])};
        --theme-slack-color: ${({theme}) => theme.themeColor.slack};
        --theme-slack-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.slack, theme.themeColor['theme-color-interval'])};
        --theme-github-color: ${({theme}) => theme.themeColor.github};
        --theme-github-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.github, theme.themeColor['theme-color-interval'])};
        --theme-linkedin-color: ${({theme}) => theme.themeColor.linkedin};
        --theme-linkedin-color-interval: ${({theme}) => styledutils.convertHexToRGB(theme.themeColor.linkedin, theme.themeColor['theme-color-interval'])};

        --theme-link-color: ${({theme}) => styledutils.field(theme.links, 'link-color', theme.links['link-color'])};
        --theme-link-decoration: ${({theme}) => styledutils.field(theme.links, 'link-decoration', theme.links['link-decoration'])};
        --theme-link-hover-color: ${({theme}) => styledutils.field(theme.links, 'link-hover-color', theme.links['link-hover-color'])};
        --theme-link-hover-decoration: ${({theme}) => styledutils.field(theme.links, 'link-hover-decoration', theme.links['link-hover-decoration'])};
        
        --theme-lateral-menu-bg-color: ${({theme}) => Color(theme.themeColor['primary']).rotate(10).desaturate(0.67).darken(0.58).hsl().string()};
        --theme-lateral-menu-current-section-bg-color: ${({theme}) => Color(theme.themeColor['primary']).rotate(12).desaturate(0.6).darken(0.42).hsl().string()};
        --theme-lateral-menu-nav-link-active-bg-color: ${({theme}) => Color(theme.themeColor['primary']).rotate(12).desaturate(0.48).hsl().string()};
        --theme-lateral-menu-nav-link-hover-bg-color: ${({theme}) => Color(theme.themeColor['primary']).rotate(11).desaturate(0.78).darken(0.325).hsl().string()};

        // --theme-lateral-menu-nav-link-active-icon-color: ${({theme}) => Color(theme.themeColor['primary']).rotate(12).desaturate(0.48).hsl().string()};
        
        --theme-lateral-menu-nav-link-icon-color: ${({theme}) => Color(theme.themeColor['primary']).saturate(1).lighten(1.125).alpha(0.3).hsl().string()};
        --theme-lateral-menu-nav-link-icon-hover-color: ${({theme}) => Color(theme.themeColor['primary']).rotate(11).desaturate(0.78).darken(0.325).hsl().string()};
        --theme-lateral-menu-company-brand-bg-color: var(--theme-lateral-menu-bg-color);
                
    // 'nav-link-icon-hover-color': 'white',

    }

    body{
        background: ${({theme}) => styledutils.field(theme.body, 'body-bg', theme.body['body-bg'])};
        font-family: ${({theme}) => styledutils.field(theme.fonts, 'font-family-base', theme.fonts['font-family-base'])};
        line-height: ${({theme}) => styledutils.field(theme.fonts, 'line-height-base', theme.fonts['line-height-base'])};
        color: ${({theme}) => styledutils.field(theme.body, 'body-color', theme.fonts['body-color'])}; 
        font-size: 0.72rem;        
    }
    
    *:focus {
    outline: none !important;
    }
    
    p{
    font-size:0.72rem;
    }
    
    a{
        color: ${({theme}) => styledutils.field(theme.links, 'link-color', theme.links['link-color'])};
        text-decoration:  ${({theme}) => styledutils.field(theme.links, 'link-decoration', theme.links['link-decoration'])};
        outline : none;
    }
    
    a:hover{
    color: ${({theme}) => styledutils.field(theme.links, 'link-hover-color', theme.links['link-hover-color'])};
    text-decoration:  ${({theme}) => styledutils.field(theme.links, 'link-hover-decoration', theme.links['link-hover-decoration'])};
    }
    
    input:focus{
    outline: none !important;
    }
    
    label{
    font-size: 0.72rem;
    margin-bottom: 0.2rem;
    font-weight: bold;
    }
    
    .react-datepicker-wrapper, .react-datepicker__input-container{
    width:100%;
    }
    
    .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
    color: #000;
    display: inline-block;
    width: 1.2rem !important;
    line-height: 1rem !important;
    text-align: center;
    margin: 0.166rem;
    }
    
    .DayPicker-wrapper{
     font-size: 0.765rem;
    }
    
    #react-confirm-alert .react-confirm-alert-overlay {
        z-index: 2000;
    }
    
    .react-confirm-alert .custom-ui{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    }
    
    .react-confirm-alert .custom-ui button{
    margin: 5px;
    }
    
    .truncate {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    
    h4{
        font-size: 1.2rem !important;
    }
    
    .table td, .table th{
        padding: 0.25rem !important;
    }
    
    .Toastify__toast{
        padding: 3px 10px 3px 15px !important;
        font-weight: 800;
    }
    
    .Toastify__toast-container--top-center {
        margin-top: 4%;
    }
    
    .Toastify__toast--success{
        background-color: ${({theme}) => styledutils.field(theme.colors, 'teal')} !important;
    }
    
    .Toastify__toast--info{
        background-color: ${({theme}) => styledutils.field(theme.colors, 'light-blue')} !important;
    }
    
    .Toastify__toast--error{
        background-color: ${({theme}) => styledutils.field(theme.colors, 'ligth-red')} !important;
    }
    
    .block-ui-overlay {
        opacity: 0 !important;
    }
    
    .badge-primary {
        background-color: var(--theme-primary-color);
    }

    .cke_top {
        background: #ffffff !important;
        padding: 0px 0px 0px !important;
    }
    
    .cke_combo, .cke_toolgroup {
        margin: 0 !important;
    }

    .ck-toolbar.ck-toolbar_floating {
        font-size: 10px;
        height: 30px;
    }
    
    .cke_autocomplete_panel{
        min-width: 350px !important;
        bottom: auto;
    }
    
    .modal-backdrop{
        opacity: 0.1 !important;
    }
    
    .popover{
        max-width: 420px !important;
        z-index: 3500;
    }
    
    .react-grid-item.react-grid-placeholder {
      background: var(--theme-primary-color) !important;
    }
`;

export default GlobalStyle;
