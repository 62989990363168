import * as types from "../../actions/multipleselection/types";

const initialState = {
    items: []
};

export const isActiveSelect = (state) => {
    const {multipleselection: {items: items}} = state
            return items;
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.TOGGLE_CHECKED:
            const {items} = state;
            const tempState = items.filter(i => i !== action.item);
            if (tempState.length === items.length) {
                return Object.assign({}, state, {
                    items: [
                        ...items,
                        action.item
                    ]
                });
            } else {
                return Object.assign({}, state, {
                    items: [
                        ...tempState
                    ]
                });
            }
        case types.CLEAN_ALL_CHECKED:
            return initialState;
        default:
            return state;
    }
};
