import React, {Fragment} from 'react';
import {ApolloProvider} from 'react-apollo';
import {Provider} from 'react-redux';
import {Router} from 'react-router-dom';
import * as dotenv from 'dotenv';
import Loadable from 'react-loadable';
import createBrowserHistory from 'history/createBrowserHistory';
import {ThemeProvider} from 'styled-components';
import {toast, ToastContainer} from 'react-toastify';
import Media from 'react-media';

import store from './Redux/store.js';
import GlobalDataProvider from './global-data-provider';
import GlobalStyle from './Components/GlobalStyle';
import GraphQlService from './graphqlService';
import PageLoader from "./Components/PageLoader"
import 'react-toastify/dist/ReactToastify.min.css';
import 'rc-slider/assets/index.css';

dotenv.config();
const history = createBrowserHistory();

const LoadableRoutes = Loadable({
    loader: () => import('./routes'),
    loading() {
        return <PageLoader/>;
    }
});

// End point App
const App = () => (
    <Media query="(max-width: 991.98px)">
        {matches => (<Provider store={store}>
            <Router history={history}>
                <React.Fragment>
                    <ApolloProvider client={GraphQlService.client}>
                        <GlobalDataProvider narrowScreen={matches}>
                            {globalDataProps => {
                                localStorage.setItem('logo_large_dark_url', globalDataProps.logoData && globalDataProps.logoData.srcLargeDark);
                                return <ThemeProvider theme={globalDataProps.theme}>
                                    <Fragment>
                                        <LoadableRoutes {...globalDataProps} narrowScreen={matches}/>
                                        <GlobalStyle/>
                                    </Fragment>
                                </ThemeProvider>
                            }}
                        </GlobalDataProvider>
                        <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} autoClose={3000}/>
                    </ApolloProvider>
                </React.Fragment>
            </Router>
        </Provider>)}
    </Media>
);

export default App;
