import {styledutils} from "./Utils";

const colors = {
    "blue-linkedin": "#356EA2",
    "red-google": "#DF4537",
    "violet": "#5c3a58",
    "blue": "#0D6CC0",
    "indigo": "#6610f2",
    "purple": "#6f42c1",
    "pink": "#e83e8c",
    "red": "#ee1d3a",
    "ligth-red": "#FF0D3A",
    "orange": "#fd7e14",
    "yellow": "#ffc107",
    "green": "#28a745",
    "teal": "#20c997",
    "light-blue": "#658DF7",
    "cyan": "#17a2b8",
    "white": "#ffffff",
    "gray-50": "#FFFEFE",
    "gray-100": "#f8f9fa",
    "gray-200": "#f5f5f5",
    "gray-300": "#eaeaea",
    "gray-400": "#ced4da",
    "gray-500": "#adb5bd",
    "gray-600": "#868e96",
    "gray-700": "#495057",
    "gray-800": "#343a40",
    "gray-900": "#212529",
    "black": "#000000"
};

const grays = {
    "50": colors['gray-50'],
    "100": colors['gray-100'],
    "200": colors['gray-200'],
    "300": colors['gray-300'],
    "400": colors['gray-400'],
    "500": colors['gray-500'],
    "600": colors['gray-600'],
    "700": colors['gray-700'],
    "800": colors['gray-800'],
    "900": colors['gray-900'],
};

const logo = {
    "title": "Company Name",
    "srcLarge": "https://tek-task-staging-assets.s3.amazonaws.com/+fileserver/logo_large_placeholder2.svg",
    "srcShort": "https://tek-task-staging-assets.s3.amazonaws.com/+fileserver/logo_short_placeholder.svg"
};

const logoTT = {
    "title": "TekTask",
    "srcLarge": "https://tek-task-staging-assets.s3.amazonaws.com/+fileserver/logo.svg",
    "srcLargeDark": "https://tek-task-staging-assets.s3.amazonaws.com/logo_tt2_large_dark.png",
    "srcShort": "https://tek-task-staging-assets.s3.amazonaws.com/+fileserver/logoShort.svg"
};

const themeColor = {
    "primary": colors['blue'],
    "secondary": colors['gray-600'],
    "success": colors['green'],
    "info": colors['cyan'],
    "warning": colors['yellow'],
    "danger": colors['red'],
    "light": colors['gray-100'],
    "google": colors['red-google'],
    "slack": colors['violet'],
    "github": colors['black'],
    "linkedin": colors['blue-linkedin'],
    "theme-color-interval": "0.8",
};

const body = {
    "body-bg": colors['gray-200'],
    "body-color": colors['gray-900']
};

const links = {
    "link-color": `var(--theme-primary-color)`,
    "link-decoration": "none",
    "link-hover-color": `var(--theme-primary-color-interval)`,
    "link-hover-decoration": "none"
};

const grid = {
    "xs": 0,
    "sm": "576px",
    "md": "768px",
    "lg": "992px",
    "xl": "1200px"
};

const containerMaxWidths = {
    "sm": "540px",
    "md": "720px",
    "lg": "960px",
    "xl": "1140px"
};

const commonMeassures = {
    'container-padding-top': '20px',
    'container-padding-right': '40px',
    'container-padding-bottom': '60px',
    'container-padding-left': '40px',
};

const components = {
    "line-height-lg": 1.5,
    "line-height-sm": 1.5,
    "border-width": 1,
    "component-active-color": colors['white'],
    "component-active-bg": themeColor['primary'],
    "caret-width": "0.3em",
    "transition-base": "all 0.2s ease-in-out",
    "transition-fade": "opacity 0.15s linear",
    "transition-collapse": "height 0.35s ease",

    "border-radius": "3px",
    "border-radius-lg": "3px",
    "border-radius-sm": "3px"
};

const fonts = {
    "font-family-open-sans": "Open Sans, sans-serif",
    "font-family-base": "Open Sans, sans-serif",
    "font-size-base": "0.72rem",
    "font-size-lg": "1.25rem",
    "font-size-sm": "0.72rem",
    "font-weight-normal": "normal",
    "font-weight-bold": "bold",
    "font-weight-base": "normal",
    "line-height-base": "1.5rem",
    "h1-font-size": "2.5rem",
    "h2-font-size": "2rem",
    "h3-font-size": "1.75rem",
    "h4-font-size": "1.5rem",
    "h5-font-size": "1.25rem",
    "h6-font-size": "1rem",
};

const buttons = {
    "input-btn-padding-y": "0.5rem",
    "input-btn-padding-x": "0",
    "input-btn-line-height": 1.25,
    "input-btn-padding-y-sm": "0.25rem",
    "input-btn-padding-x-sm": "0.5rem",
    "input-btn-line-height-sm": 1.5,
    "input-btn-padding-y-lg": "0.5rem",
    "input-btn-padding-x-lg": "1rem",
    "input-btn-line-height-lg": 1.5,
    "btn-height": "1.85rem",
    "btn-br-radius": "3px",
    "btn-font-weight": fonts["font-weight-normal"],
    "btn-box-shadow": "8px 8px 13px rgba(1, 1, 2, 0.15)",
    "btn-focus-box-shadow": "8px 8px 13px rgba(1, 1, 2, 0.15)",
    "btn-active-box-shadow": "8px 8px 13px rgba(1, 1, 2, 0.15)",
    "btn-link-disabled-color": colors["gray-600"],
    "btn-block-spacing-y": "0.5rem",
    "btn-border-radius": components["border-radius"],
    "btn-border-radius-lg": components["border-radius-lg"],
    "btn-border-radius-sm": components["border-radius-sm"],

    "btn-transition": "all 0.15s ease-in-out",
    'btn-color': colors['gray-50'],
    'btn-padding-x': '0.9rem',
    'btn-padding-y': '0',

    'btn-link-font-size': '0.85rem',
};

const indicators = {
    'favorite-color': `var(--theme-primary-color)`,
    // 'rgba(13,108,192,1)',
    'not-favorite-color': 'rgba(40,44,46,.3)',
};

const forms = {
    "input-border-radius": components["border-radius"],
    "input-border-radius-lg": components["border-radius-lg"],
    "input-border-radius-sm": components["border-radius-sm"],
    'input-padding-x': '0.9rem',
    "input-font-size": "0.72rem",
    'input-padding-y': '0',

    "input-bg": colors['white'],
    "input-disabled-bg": colors["gray-200"],
    "input-color": colors["gray-700"],
    "input-border-color": styledutils.convertHexToRGB(colors["black"], 0.35),
    "input-btn-border-width": `${components["border-width"]}px`,
    "input-box-shadow": `inset 0 1px 1px ${styledutils.convertHexToRGB(colors["black"], 0.15)}`,
    "input-focus-bg": colors['white'],
    "input-focus-border-color": styledutils.convertHexToRGB(themeColor['primary'], themeColor["theme-color-interval"]),
    "input-focus-box-shadow": `inset 0 1px 1px ${styledutils.convertHexToRGB(colors["black"], 0.15)}`,
    "input-focus-color": colors["gray-700"],
    "input-placeholder-color": colors["gray-600"],
    "input-height-border": `${components["border-width"] * 2}px`,
    "input-line-height": "1.5rem",
    "input-height-inner-sm": "",
    "input-height-sm": "",
    "input-height-inner-lg": "",
    "input-height-lg": "",
    "input-transition": "border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s",
    "form-text-margin-top": "0.25rem",
    "form-group-margin-bottom": "1rem",
    "input-group-addon-bg": colors["gray-200"],
    "input-group-addon-border-color": styledutils.convertHexToRGB(colors["black"], 0.15),
    "custom-control-gutter": "1.5rem",
    "custom-control-spacer-y": "0.25rem",
    "custom-control-spacer-x": "1rem",
    "custom-control-indicator-size": "1rem",
    "custom-control-indicator-bg": "#ddd",
    "custom-control-indicator-bg-size": "50% 50%",
    "custom-control-indicator-box-shadow": `inset 0 0.25rem 0.25rem ${styledutils.convertHexToRGB(colors["black"], 0.15)}`,
    "custom-control-indicator-disabled-bg": colors["gray-200"],
    "custom-control-description-disabled-color": colors["gray-600"],
    "custom-control-indicator-checked-color": colors.white,
    "custom-control-indicator-checked-bg": themeColor.primary,
    "custom-control-indicator-checked-box-shadow": "none",
    "custom-control-indicator-focus-box-shadow": `0 0 0 1px ${body["body-bg"]}, 0 0 0 3px ${themeColor.primary}`,
    "custom-control-indicator-active-color": colors.white,
    "custom-control-indicator-active-bg": styledutils.convertHexToRGB(themeColor["primary"], 0.15),
    "custom-control-indicator-active-box-shadow": "none",

    "input-height": "1.85rem",
    'input-min-width': '175px;'
};

const dropdown = {
    "dropdown-width": "7.5rem",
    "dropdown-border-radius": "0.3rem",
    "dropdown-divider-bg": colors["gray-200"],
    "dropdown-box-shadow": `8px 8px 13px rgba(1, 1, 2, 0.2)`,
    "dropdown-buttontoogle-radius": "3.125rem",
    "dropdown-toogle-font-size": "0.72rem",
    "dropdown-link-height": "1.85rem",
    "dropdown-link-font-size": "0.72rem",
    "dropdown-link-color": "#838383",
    "dropdown-link-hover-color": colors["gray-600"],
    "dropdown-link-active-bg": `var(--theme-primary-color-interval)`,
};

const navbar = {
    "navbar-brand-font-size": "",
    "nav-link-height": "",
    "navbar-brand-height": "",
    "navbar-brand-padding-y": "",
    "navbar-toggler-padding-y": "",
    "navbar-toggler-padding-x": "",
    "navbar-toggler-font-size": "",
    "navbar-toggler-border-radius": "",
    "navbar-item-spacing": "0rem 1.1rem 0rem 0.5rem",
    "navbar-item-fontsize": "0.72rem",
    "navbar-item-color": "#eaeaea",
    "navbar-item-hover-color": themeColor.primary,
    "navbar-dark-active-color": styledutils.convertHexToRGB(colors["white"], 1),
    "navbar-dark-disabled-color": styledutils.convertHexToRGB(colors["white"], 0.25),
    "navbar-dark-toggler-border-color": styledutils.convertHexToRGB(colors["white"], 0.1),
    "navbar-brand-title": "",
    "navbar-brand-logo-src": "",

    "navbar-bg-color": "white",
    "navbar-padding": `0 ${commonMeassures["container-padding-left"]}`,
    "navbar-height": "70px",
    "navbar-items-space-between": "20px",
    "navbar-box-shadow": "0px 0px 10px 0px rgba(46, 54, 68, 0.2)",
};

const lateralMenu = {
    'bg-color': styledutils.convertHexToRGB('#202939', 1),
    'current-section-bg-color': styledutils.convertHexToRGB('#273550', 1),
    'nav-link-hover-bg-color': styledutils.convertHexToRGB('#374051', 1),
    'nav-link-active-bg-color': styledutils.convertHexToRGB('#385793', 1),

    'nav-link-icon-color': 'rgba(181,220,255,.3)',
    'nav-link-icon-hover-color': 'white',
};

const card = {
    "card-spacer-y": "0.75rem",
    "card-spacer-x": "1.25rem",
    "card-border-width": "1px",
    "card-border-radius": components["border-radius"],
    "card-border-color": styledutils.convertHexToRGB(colors["black"], 0.125),
    "card-inner-border-radius": "",
    "card-cap-bg": styledutils.convertHexToRGB(colors["black"], 0.03),
    "card-bg": colors.white,
    "card-box-shadow": '0px 10px 20px 2px rgba(0,0,0,0.2)'
};

const headerInfo = {
    "headerinfo-height": "5.3rem",
    "headerinfo-background": colors['gray-300']
};

const footer = {
    "footer-bg": grays['400'],
};

const modal = {
    "modal-background": "#fff",
};


/// todo: readjust the theme

const color = {
    primary: '#378bff',
    warning: '#ffbf00',
    danger: '#ff2e00',
    success: '#85cc00',
    info: '#378bff',
    black: '#0e1800',
    backgroundLight: '#f5f7fb',
    background: '#dadada1f',
    default: '#FFFFFF',
    grey: '#f0f0f0',
    greyStrong: '#333333',
    greyLight: 'rgba(152, 150, 150,0.5)',
    greyLight1: '#ebebeb',
    greyLight2: '#585757',
    innerBackground: '#F9F9FB',
    textColor: '#666',
    header: '#eaeaea',
    transparent: 'transparent',
    border: '#F5F5F5'
};

const table = {
    topRadius: 8,
    bottomRadius: 0,
    addBoderColor: '#C7C7C7',
    // header
    header: {
        border: {
            size: 2,
            color: color.border,
            highlight: color.greyLight
        },
        background: '#dbe0e6',
        color: '#6d7175',
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '0.72rem',
        fontWeight: 600,
        height: '35px',
        backgroundAdd: 'white',
        backgroundHover: '#2626268a'
    },
    // cells
    cell: {
        border: {
            size: 2,
            color: color.border,
            highlight: color.greyLight
        },
        background: color.default,
        backgroundHover: '#fbf7f7',
        color: body["body-color"],
        fontFamily: 'Open Sans, sans-serif',
        fontSize: '0.72rem'
    },
    // custom column style
    addColumn: {
        header: {
            background: '#fbfcfc',
            resizeDashed: true
        },
        cell: {
            border: {
                color: 'transparent'
            },
            background: color.default,
            color: body["body-color"],
            fontFamily: 'Open Sans, sans-serif',
            fontSize: '0.72rem'
        }
    },
};

const status = {
    defaultBackground: '#dbe0e64d',
    defaultBorderRadius: '3px',
    defaultHeight: '30px'
};

export {
    colors,
    status,
    grays,
    logo,
    themeColor,
    body,
    table,
    links,
    grid,
    containerMaxWidths,
    components,
    fonts,
    buttons,
    forms,
    indicators,
    dropdown,
    navbar,
    card,
    lateralMenu,
    headerInfo,
    modal
}
