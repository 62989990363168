import {ApolloClient} from 'apollo-client';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {HttpLink} from 'apollo-link-http';
import {onError} from 'apollo-link-error';
import {ApolloLink} from 'apollo-link';
import {Observable} from "apollo-client/util/Observable";

class GraphqlService {
    // constructor() {
    //     this.client = new ApolloClient({
    //         uri: `${process.env.REACT_APP_BACKEND_URL}/api/graphql`,
    //         // uri: `https://backend.blueoceantech.tek-task.com/api/graphql`,
    //         request: operation => {
    //             const token = this.getStorageItem(localStorage,'tek_task_access_token')||this.getStorageItem(sessionStorage,'tek_task_access_token');
    //             const headers = token?{ authorization: `Bearer ${token}`}:{};
    //             operation.setContext({
    //                 headers,
    //             });
    //         },
    //     });
    // }

    constructor() {
        let cache = new InMemoryCache();
        let request = async (operation) => {
            const token = this.getStorageItem(localStorage, 'tek_task_access_token') || this.getStorageItem(sessionStorage, 'tek_task_access_token');
            const headers = token ? {authorization: `Bearer ${token}`} : {};
            operation.setContext({
                headers,
            });
        };
        const requestLink = new ApolloLink((operation, forward) =>
            new Observable(observer => {
                let handle;
                Promise.resolve(operation)
                    .then(oper => request(oper))
                    .then(() => {
                        handle = forward(operation).subscribe({
                            next: observer.next.bind(observer),
                            error: observer.error.bind(observer),
                            complete: observer.complete.bind(observer),
                        });
                    })
                    .catch(observer.error.bind(observer));

                return () => {
                    if (handle) handle.unsubscribe();
                };
            })
        );
        this.client = new ApolloClient({
            link: ApolloLink.from([
                onError(({graphQLErrors, networkError}) => {
                    if (graphQLErrors)
                        graphQLErrors.map(({message, locations, path}) =>
                            console.log(
                                `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
                            ),
                        );
                    if (networkError) console.log(`[Network error]: ${networkError}`);
                }),
                requestLink,
                new HttpLink({
                    uri: `${process.env.REACT_APP_BACKEND_URL}/api/graphql`,
                    credentials: 'same-origin'
                })
            ]),
            cache
        });
    }


    getStorageItem(storage,item){
        return storage[item];
    }

    query(query){
        return this.client.query(query);
    }

    readQuery(query) {
        return this.client.readQuery(query);
    }

    writeQuery(query) {
        return this.client.writeQuery(query);
    }

    mutate(query){
        return this.client.mutate(query);
    }

    getClient(){
        return this.client;
    }
}

export default new GraphqlService();

