import * as types from "../../actions/space/types";

const initialState = {
    currentSpace: null,
    payload: null
};

export default (state = initialState, action = {}) => {
    switch (action.type) {
        case types.CURRENT_SPACE:
            return Object.assign({}, state, {
                currentSpace: action.currentSpace,
                payload: action.payload
            });
        default:
            return state;
    }
};
