import * as types from "./types";

export const putCurrentUser = (user, payload) => {
    return dispatch => {
        dispatch({
            type: types.CURRENT_USER,
            currentUser: user,
            payload: payload
        });
    };
};

export const putRegisterUser = (user, payload) => {
    return dispatch => {
        dispatch({
            type: types.REGISTER_USER,
            registerUser: user,
            payload: payload
        });
    };
};


export const sentEmail = register => {
    return dispatch => {
        dispatch({
            type: types.REGISTER_COMPLETED,
            register: register
        });
    };
};

