import axios from 'axios';

class Service {

    constructor(headers, baseURL) {
        let service = axios.create({
            baseURL: baseURL || process.env.REACT_APP_BACKEND_URL,
            headers: headers
        });
        service.interceptors.response.use(this.handleSuccess, this.handleError);
        this.service = service;
    }

    handleSuccess(response) {
        return response;
    }

    handleError = (error) => {
        return Promise.reject({status: error.response.status, response: error.response})
    }

    get(path, callback) {
        return this.service.get(path);
    }

    patch(path, payload, callback) {
        return this.service.request({
            method: 'PATCH',
            url: path,
            responseType: 'json',
            data: payload
        })
    }

    post(path, payload, callback) {
        return this.service.request({
            method: 'POST',
            url: path,
            data: payload
        });
    }
}

export default Service;
