import React, {Component} from 'react';
import Proptypes from 'prop-types'
import {NavLink} from 'react-router-dom'
import {RootLoader, LoaderContainer, Loader, LoaderImage} from './styled'

const PageLoader = (props) => {
    return <RootLoader>
        <LoaderContainer>
            <Loader/>
            <LoaderImage>
                <img src="/assets/image/Icon.svg"/>
                <div className="text">
                    <span style={{color: "#3498db", fontSize: 14}}>Loading...</span>
                </div>
            </LoaderImage>
        </LoaderContainer>
    </RootLoader>
}

PageLoader.propTypes = {}

export default PageLoader

