const initialState = {
    nomenclator: {
        severity_tickets: [
            {value: "Minor", label: "Minor"},
            {value: "Major", label: "Major"},
            {value: "Critical", label: "Critical"}
        ],
        type_tickets: [
            {value: "Bug", label: "Bug"},
            {value: "Change request", label: "Change request"}
        ],
        status_tickets: [
            {value: "New", label: "New"},
            {value: "Evaluated", label: "Evaluated"},
            {value: "Approved", label: "Approved"},
            {value: "Assigned", label: "Assigned"},
            {value: "Awaiting payment", label: "Awaiting payment"},
            {value: "In progress", label: "In progress"},
            {value: "Ready for review", label: "Ready for review"},
            {value: "Postpone", label: "Postpone"},
            {value: "Rejected", label: "Rejected"},
            {value: "Unapproved", label: "Unapproved"}
        ],
        status_project: [
            {value: "Reviewing", label: "Reviewing", color: '#A084D2', name: "Reviewing"},
            {value: "In specification", label: "In specification", color: '#6C3FBA', name: "In specification"},
            {value: "Ready for payment", label: "Ready for payment", color: '#311A59', name: "Ready for payment"},
            {value: "Rejected", label: "Rejected", color: '#F13D3F', name: "Rejected"},
            {value: "In progress", label: "In progress", color: '#0D6CC0', name: "In progress"},
            {value: "Ready for review", label: "Ready for review", color: '#02B9C4', name: "Ready for review"},
            {value: "Completed", label: "Completed", color: '#06C270', name: "Completed"},
            {value: "Closed", label: "Closed", color: '#919191', name: "Closed"}
        ],
        type_project: [
            {value: "Software", label: "Software"},
            {value: "Hosting", label: "Hosting"},
            {value: "Sponcer", label: "Sponcer"}
        ],
        status_ini_project: [
            {value: "New project", label: "New project"},
            {value: "Existing project", label: "Existing project"}
        ],
        service_billingrecord: [
            {value: "Hosting and operation", label: "Hosting and operation"},
            {value: "Development", label: "Development"},
            {value: "Consult", label: "Consult"}
        ],
        employee_roles: [
            {value: "ADMIN_SPACE", label: "Admin space"},
            {value: "EMPLOYEED_CEO", label: "CEO"},
            {value: "EMPLOYEED_CTO", label: "CTO"},
            {value: "EMPLOYEED_TLEADER", label: "Team Leader"},
            {value: "EMPLOYEED_DEVELOPER", label: "Developer"},
            {value: "EMPLOYEED_DESIGNER", label: "Designer"}
        ],
        client_roles: [
            {value: "CLIENT_MANAGER", label: "Manager"},
            {value: "CLIENT_EMPLOYEED", label: "Employeed"},
        ],
        status_user: [
            {value: "Pending", label: "Pending"},
            {value: "Completed", label: "Completed"}
        ],
        notification_frecuency: [
            {value: "Instant", label: "Instant"},
            {value: "Once a day", label: "Once a day"},
            {value: "Once a week", label: "Once a week"}
        ],
        permissions: {
            edit_ticket: 'Edit ticket',
            management_members: 'Client management',
            view_timesheet: 'Show timesheet',
            view_payment: 'Show payments',
            execute_payment: 'Execute payments',
            edit_aggreement: 'Edit aggreement',
            create_project: 'Create project',
            view_aggreement: 'Show aggreements',
            edit_ratecontrol: 'Edit rate control',
            edit_project: 'Edit project',
            list_projectadmin: 'List projects',
            list_ticket: 'List tickets',
            list_reports: 'List reports',
            rate_control: 'View rate control',
            show_admin_project_detail: 'Show project details',
            create_aggreement: 'Create aggreement',
            add_employeed: 'Add employee',
            show_settings: 'Change settings project',
            show_billings: 'Show billings',
            list_projectclient: 'List project\'s client',
            create_feeback: 'Add feedback',
            create_ticket: 'Create ticket',
            show_company: 'Show company',
            accept_aggreement: 'Accept aggreement',
            approve_payment: 'Approve payments',
            comment_task: 'Comment task',
            activate_close_milestone: 'Start and close sprint',
            modify_planning: 'Edit planning',
            change_status_project: 'Change project\'s status',
            modify_column_planning: 'Edit column planning',
            show_blockchain: 'Show blockchain',
            other_sections: 'Other sections',
            solve_emergency: 'Solve emergencies',
            manage_emergency: 'Emergency management'
        },
        metrics: {
            "MAX": "Max",
            "MIN": "Min",
            "COUNT": "Count",
            "UNIQUE_COUNT": "Distinc",
            "AVG": "Average",
            "STD": "Standard deviation",
            "SUM": "Sum",
            "SUMQ": "Square Sum",
            "VAR": "Standard variation",
        },
        dimensions: {
            "TERMS": "Terms",
            "RANGE": "Range",
            "HISTOGRAM": "Histogram",
            "DATE_RANGE": "Date range",
            "DATE_HISTOGRAM": "Date histogram"
        },
        filters: {
            "TERM": "Term",
            "TERMS": "Terms",
            "REGEX": "Regex",
            "WILDCARD": "Wilcard",
            "RANGE": "Range"
        }
    }
};

export default (state = initialState, action = {}) => {
    return state;
};
