import * as types from './types';

export const toggleCollapse = () => {
    return dispatch => dispatch({
        type: types.TOGGLE_COLLAPSE
    })
};

export const setLateralMenuCollapse = (collapse) => {
    return dispatch => dispatch({
        type: types.SET_LATERAL_MENU_COLLAPSE,
        collapse: collapse
    })
};