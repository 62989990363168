import * as types from "./types";

export const putCurrentSpace = (space, payload) => {
    return dispatch => {
        dispatch({
            type: types.CURRENT_SPACE,
            currentSpace: space,
            payload: payload
        });
    };
};
