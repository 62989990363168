import {combineReducers} from "redux";
import space from "../reducers/space";
import user from "../reducers/user";
import modal from "../reducers/modal";
import sidebar from "../reducers/sidebar";
import nomenclator from "../reducers/nomenclator";
import dropdownpopover from "../reducers/dropdownpopover"
import multipleselection from "../reducers/multipleselection"
import lateralMenu from "../reducers/lateralMenu"
import favoriteentities from "../reducers/favoriteentities"

const index = combineReducers({
    space,
    user,
    modal,
    sidebar,
    nomenclator,
    dropdownpopover,
    multipleselection,
    lateralMenu,
    favoriteentities
});

export default index;
